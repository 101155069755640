<template>
  <div class="team">
    <h2>Events Page</h2>
    <eventCalendar />
  </div>
</template>

<script>
// @ is an alias to /src
import eventCalendar from "@/components/events/calendar";
export default {
  name: "Tasks",
  components: {
    eventCalendar,
  },
};
</script>
