<template>
  <div>
    <v-row class="fill-height">
      <v-col
        ><v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today </v-btn
            ><v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>

            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <router-link :to="{ name: 'EventList' }">
              <v-btn fab text small color="grey darken-2">
                <v-icon>
                  mdi-format-list-bulleted
                </v-icon>
              </v-btn>
            </router-link>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="deep-purple accent-4"
            class="mb-1"
          ></v-progress-linear>
          <v-calendar
            ref="calendar"
            v-model="focus"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            color="primary"
            @change="showEvents"
            @click:event="editEvent"
            @click:date="dialogOpen"
            @click:time="openTime"
          >
          </v-calendar
          ><v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectedOpen = false">
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.description"></span>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <div>
      <v-dialog v-model="dialog" width="1200" :key="key">
        <v-card>
          <v-card-title class="title text-h5">
            <span v-if="eventId === 0">
              ADD EVENT
            </span>
            <span v-else>
              EDIT EVENT
            </span>
          </v-card-title>
          <v-card-text class="py-5">
            <addEvent :id="eventId" :date="date" :time="time" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
import addEvent from "./create.vue";
export default {
  components: { addEvent },
  data() {
    return {
      focus: "",
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      dialog: false,
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      modes: ["stack", "column"],
      selectedType: "month",
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      key: 0,
      eventId: 0,
      date: "",
      time: "",
      loading: false,
    };
  },
  mounted() {
    util.event.$on("clearAddEmailParticipant", () => {
      this.getEvents();
      this.key++;
      this.dialog = false;
    });
  },
  methods: {
    showEvents() {
      this.getEvents();
    },
    viewDay({ date }) {
      alert(date);
    },
    open(event) {
      alert(event.title);
    },
    dialogOpen({ date }) {
      this.eventId = 0;
      this.date = date;
      this.time = "00:00";
      this.key++;
      this.dialog = true;
    },
    openTime({ date, time }) {
      this.eventId = 0;
      this.date = date;
      this.time = time;
      this.key++;
      this.dialog = true;
    },
    getEvents() {
      this.loading = true;
      let events = [];
      util.http({ url: "event/get-all", method: "get" }).then((res) => {
        this.loading = false;
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          events = res.data.data;
          events.map((e) => {
            e.start = new Date(e.date + " " + e.time);
            e.color = e.event_type.color;
          });
          this.events = events;
        }
      });
    },
    // getTasks() {
    //   let tasks = [];
    //   util.http({ url: "task/get-all", method: "get" }).then((res) => {
    //     if (res.data.status == 0) {
    //       util.notify(0, res.data.errors[0]);
    //     } else {
    //       tasks = res.data.data;
    //       tasks.map((e) => {
    //         e.name = e.title;
    //         e.start = new Date(e.date_time);
    //         e.color = "success";
    //       });
    //       this.events = this.events.concat(tasks);
    //     }
    //   });
    // },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    editEvent({ event }) {
      this.eventId = event.id;
      this.date = "";
      this.time = "00:00";
      this.key++;
      this.dialog = true;
    },
  },
  computed: {
    // convert the list of events into a map of lists keyed by date
    eventsMap() {
      const map = {};
      this.events.forEach((e) => (map[e.date] = map[e.date] || []).push(e));
      return map;
    },
  },
};
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}
.title {
  background: #1d2b58 !important;
  color: #fff;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
a {
  text-decoration: none;
  color: #fff !important ;
}
</style>
